import { Box, Flex, Link, Text } from '@chakra-ui/react'
import { useGetAggregatedTransactions } from 'generated/reactQueryClient'
import dayjs from 'lib/dayjs'
import { useRouter } from 'next/router'
import { PAGE_LENGTH } from 'constants/common'
import { Routes } from 'constants/routes'
import AddressCard from 'components/AddressCard'
import Label from 'components/Label'
import TransactionIcon from './TransactionIcon'
import WidgetSkeleton from './WidgetSkeleton'

const LatestTransactions = () => {
  const { push } = useRouter()
  const { data, isLoading } = useGetAggregatedTransactions({
    limit: PAGE_LENGTH,
    offset: 0,
    txType: 'L2',
  })

  return (
    <AddressCard
      flex={1}
      title="Latest Transactions"
      onViewAllClick={() => {
        void push(Routes.transactions())
      }}
    >
      {isLoading ? (
        <WidgetSkeleton />
      ) : (
        data?.data.map(({ from, timestamp, to, l2TxHash, txIndex, value }) => (
          <AddressCard.Item key={l2TxHash}>
            <Flex alignItems="center">
              <TransactionIcon />
              <Box overflow="hidden" ml={6}>
                <Flex>
                  <Text>Tx#{txIndex}</Text>
                  <Link
                    noOfLines={1}
                    ml={1}
                    mr={8}
                    href={Routes.transactionDetail({ txHash: String(l2TxHash), activeTab: 0 })}
                  >
                    <Text>{l2TxHash}</Text>
                  </Link>
                </Flex>
                <Flex gap={1}>
                  <Text>From</Text>
                  <Link noOfLines={1} href={Routes.addressDetail({ hash: String(from) })}>
                    <Text>{from}</Text>
                  </Link>
                  <Text ml={3}>To</Text>
                  <Link noOfLines={1} href={Routes.addressDetail({ hash: String(to) })}>
                    <Text>{to}</Text>
                  </Link>
                </Flex>
                <Flex mt={1}>
                  <Text variant="small" color="darkGray">
                    {dayjs(timestamp).fromNow()}
                  </Text>
                  <Label ml="auto" variant="tiny">
                    {value} ETH
                  </Label>
                </Flex>
              </Box>
            </Flex>
          </AddressCard.Item>
        ))
      )}
    </AddressCard>
  )
}

export default LatestTransactions
