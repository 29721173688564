import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Link, LinkProps, Text } from '@chakra-ui/react'

type IconOrientation = 'left' | 'right'

type ExternalLinkProps = {
  href: string
  label: string
  iconOrientation?: IconOrientation
} & LinkProps

const ExternalLink = ({ href, label, iconOrientation = 'left', ...props }: ExternalLinkProps) => (
  <Link target="_blank" href={href} wordBreak="break-word" {...props}>
    {iconOrientation === 'left' && <ExternalLinkIcon mb={1} mr={1} />}
    <Text as="span">{label}</Text>
    {iconOrientation === 'right' && <ExternalLinkIcon mb={1} ml={1} />}
  </Link>
)

export default ExternalLink
