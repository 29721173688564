import { Box, Flex, Heading } from '@chakra-ui/react'
import HomeLayout from 'layouts/HomeLayout/HomeLayout'
import { PAGE_OFFSET_X } from 'lib/chakra/theme'
import Head from 'next/head'
import LatestBatches from 'components/HomeWidgets/LatestBatches'
import LatestTransactions from 'components/HomeWidgets/LatestTransactions'
import LatestTransactionsEnqueued from 'components/HomeWidgets/LatestTransactionsEnqueued'
import OverviewWidget from 'components/OverviewWidget'
import SearchBar from 'components/SearchBar'

const Home = () => (
  <div>
    <Head>
      <title>Dashboard</title>
    </Head>
    <Box py={10} px={PAGE_OFFSET_X} bg="secondary">
      <Heading variant="xl" color="white">
        The explorer
      </Heading>
      <SearchBar mt={4} maxW={900} />
    </Box>
    <Box mt={6} mb={5} px={PAGE_OFFSET_X}>
      <OverviewWidget />
      <Flex mt={6} gap={5} flexWrap="wrap">
        <LatestBatches />
        <LatestTransactions />
        <LatestTransactionsEnqueued />
      </Flex>
    </Box>
  </div>
)

Home.getLayout = (page: React.ReactNode) => <HomeLayout>{page}</HomeLayout>

export default Home
