import { useMedia } from 'react-use'
import { Theme, useTheme } from '@chakra-ui/react'

export const useIsDesktopWidth = () => {
  const {
    breakpoints: { xl },
  } = useTheme<Theme>()
  const isDesktopWidth = useMedia(`(min-width: ${xl})`)

  return { isDesktopWidth }
}
