import { Flex, Text } from '@chakra-ui/react'
import { BORDER_RADIUS_PILL } from 'lib/chakra/theme'

const ICON_SIZE = 10

const TransactionIcon = () => (
  <Flex
    p={ICON_SIZE / 2}
    w={ICON_SIZE}
    h={ICON_SIZE}
    borderRadius={BORDER_RADIUS_PILL}
    justifyContent="center"
    alignItems="center"
    bgColor="lightGray"
  >
    <Text color="darkGray" variant="smallBold">
      Tx
    </Text>
  </Flex>
)

export default TransactionIcon
