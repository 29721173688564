import React from 'react'
import { Box, Button, Card, CardProps, Flex, Heading } from '@chakra-ui/react'

type AddressCardProps = {
  children: React.ReactNode
  title: string
  onViewAllClick: () => void
} & CardProps

const AddressCard = ({ children, title, onViewAllClick, ...props }: AddressCardProps) => (
  <Card maxH={500} minW={400} {...props}>
    <Flex
      px={6}
      py={4}
      alignItems="center"
      justifyContent="space-between"
      borderBottom="1px solid"
      borderBottomColor="lightGray"
    >
      <Heading variant="s">{title}</Heading>
      <Button variant="secondary" size="small" borderRadius={4} onClick={onViewAllClick}>
        View all
      </Button>
    </Flex>
    <Box overflow="hidden" _hover={{ overflow: 'auto' }}>
      {children}
    </Box>
  </Card>
)

const Item = ({ children }: React.PropsWithChildren) => (
  <Box _notFirst={{ borderTop: '1px solid', borderTopColor: 'lightGray' }} px={6} py={4}>
    {children}
  </Box>
)

AddressCard.Item = Item

export default AddressCard
