import web3qs from 'lib/web3qs'

export const getIsContractMethodRead = (stateMutability: string) =>
  ['view', 'pure'].includes(stateMutability)

export const getIsContractMethodWrite = (stateMutability: string, type: string) =>
  ['payable', 'nonpayable'].includes(stateMutability) && type === 'function'

export const formatUSD = (price: number) =>
  price.toLocaleString('en-US', { style: 'currency', currency: 'USD' })

export const getGasUsedPercentage = (gasUsed: number, gasLimit: number) => {
  if (gasLimit === 0) {
    return null
  }

  return `${((gasUsed / gasLimit) * 100).toFixed(2)}%`
}

export const getGasFee = (gasUsed: number, baseFeePerGas = 0) =>
  `${(Number(web3qs.utils.fromWei(String(baseFeePerGas))) * gasUsed).toFixed(20)} ETH`
