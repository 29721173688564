import { Flex } from '@chakra-ui/react'
import Footer from 'components/Footer'
import NavBar from 'components/NavBar'

type HomeLayoutProps = {
  children?: React.ReactNode
}

const HomeLayout = ({ children }: HomeLayoutProps) => (
  <Flex minH="100vh" flexDir="column">
    <NavBar />
    {children}
    <Footer />
  </Flex>
)

export default HomeLayout
