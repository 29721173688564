import { Box, Flex, Skeleton, SkeletonCircle } from '@chakra-ui/react'
import AddressCard from 'components/AddressCard'

const WidgetSkeleton = () => (
  <Flex flexDir="column">
    {[...Array(10)].map((idx) => (
      <AddressCard.Item key={idx}>
        <Flex alignItems="center">
          <SkeletonCircle size="10" startColor="gray" />
          <Skeleton ml={6} flexGrow={1} startColor="darkGrey">
            <Box h={12} />
          </Skeleton>
        </Flex>
      </AddressCard.Item>
    ))}
  </Flex>
)

export default WidgetSkeleton
