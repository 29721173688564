import {
  FaCheck,
  FaExternalLinkAlt,
  FaRegClock,
  FaSpinner,
  FaSyncAlt,
  FaTimes,
} from 'react-icons/fa'
import { BoxProps, Flex, Icon, Text } from '@chakra-ui/react'

const VARIANTS = {
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary',
  success: 'success',
  danger: 'danger',
  warning: 'warning',
  link: 'link',
  tiny: 'tiny',
}

const APPEARANCE = {
  [VARIANTS.primary]: {
    color: 'mediumGray',
    bgColor: 'lightGray',
    icon: FaSyncAlt,
  },
  [VARIANTS.secondary]: {
    color: 'primary',
    bgColor: 'lightGray',
    icon: FaSpinner,
  },
  [VARIANTS.tertiary]: {
    color: 'white',
    bgColor: 'primary',
  },
  [VARIANTS.success]: {
    color: 'green',
    bgColor: 'lightGreen',
    icon: FaCheck,
  },
  [VARIANTS.danger]: {
    color: 'red',
    bgColor: 'lightRed',
    icon: FaTimes,
  },
  [VARIANTS.warning]: {
    color: 'orange',
    bgColor: 'lightOrange',
    icon: FaRegClock,
  },
  [VARIANTS.link]: {
    color: 'linkColor',
    bgColor: 'lightBlue',
    icon: FaExternalLinkAlt,
  },
  [VARIANTS.tiny]: {
    color: 'darkGray',
    bgColor: 'lightGray',
    textVariant: 'tiny',
  },
}

type LabelProps = {
  children: React.ReactNode
  variant?: keyof typeof VARIANTS
  hasIcon?: boolean
} & BoxProps

const Label = ({ hasIcon, children, variant = 'primary', ...props }: LabelProps) => {
  const { bgColor, color, icon, textVariant } = APPEARANCE[variant]

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      bgColor={bgColor}
      color={color}
      borderRadius="base"
      py={1}
      px={2}
      {...(props.onClick && { _hover: { cursor: 'pointer' } })}
      {...props}
    >
      {hasIcon && icon && <Icon as={icon} boxSize={2.5} mr={1} />}
      <Text variant={textVariant ?? 'smallBold'}>{children}</Text>
    </Flex>
  )
}

export default Label
