import { Box, Flex, Link, Text } from '@chakra-ui/react'
import { useGetBatches } from 'generated/reactQueryClient'
import dayjs from 'lib/dayjs'
import { useRouter } from 'next/router'
import { PAGE_LENGTH } from 'constants/common'
import { Routes } from 'constants/routes'
import AddressCard from 'components/AddressCard'
import ExternalLink from 'components/ExternalLink/ExternalLink'
import WidgetSkeleton from './WidgetSkeleton'

const LatestBatches = () => {
  const { push } = useRouter()
  const { data, isLoading } = useGetBatches({
    limit: PAGE_LENGTH,
    offset: 0,
  })

  return (
    <AddressCard
      flex={1}
      title="Latest L1 Batches"
      onViewAllClick={() => {
        void push(Routes.batches())
      }}
    >
      {isLoading ? (
        <WidgetSkeleton />
      ) : (
        data?.data.map(({ batchIndex, l1Timestamp, l1TxHash, txCount }) => (
          <AddressCard.Item key={batchIndex}>
            <Flex alignItems="center">
              <Text p={3} borderRadius={4} bgColor="lightGray" color="darkGray" variant="smallBold">
                BA
              </Text>
              <Flex overflow="hidden" ml={4}>
                <Box minW="fit-content" mt={1}>
                  <Link href={Routes.batchDetail({ id: String(batchIndex) })}>
                    <Text variant="regularBold">{batchIndex}</Text>
                  </Link>
                  <Text mt={1} variant="small" color="darkGray">
                    {dayjs(l1Timestamp).fromNow()}
                  </Text>
                </Box>
                <Box overflow="hidden" ml={6}>
                  <Flex alignItems="center">
                    <Text>Hash</Text>
                    <ExternalLink
                      ml={1}
                      isTruncated
                      href={`https://etherscan.io/tx/${l1TxHash}`}
                      label={l1TxHash}
                    />
                  </Flex>
                  <Text mt={1} variant="small" color="darkGray">
                    {txCount} txns
                  </Text>
                </Box>
              </Flex>
            </Flex>
          </AddressCard.Item>
        ))
      )}
    </AddressCard>
  )
}

export default LatestBatches
