import React from 'react'
import { IconType } from 'react-icons'
import { FaCheck, FaEthereum, FaGlobe, FaListAlt } from 'react-icons/fa'
import { Card, Flex, Heading, Icon, Skeleton, SkeletonCircle, Text } from '@chakra-ui/react'
import { useOverviewQuery } from 'apollo/generated/graphqlClient'
import { useGetLatestBatch, useGetTransactionStats } from 'generated/reactQueryClient'
import { BORDER_RADIUS_PILL } from 'lib/chakra/theme'
import { useIsDesktopWidth } from 'components/Breakpoint'
import { formatUSD } from 'utils/common'

type OverviewWidgetCardProps = {
  name: string
  label: string
  subLabel?: string
  difference?: string
  icon: IconType
  isLoading: boolean
}

const OverviewWidgetCard = ({
  name,
  label,
  subLabel,
  difference,
  icon,
  isLoading,
}: OverviewWidgetCardProps) => {
  const { isDesktopWidth } = useIsDesktopWidth()

  return (
    <Flex
      px={8}
      flex="1"
      flexBasis={{ base: '50%', xl: '25%' }}
      py={6}
      alignItems="center"
      justifyContent="center"
      {...(isDesktopWidth
        ? {
            _notLast: {
              borderRight: 'solid 1px',
              borderRightColor: 'lightGray',
            },
          }
        : {
            borderBottom: 'solid 1px',
            borderRight: 'solid 1px',
            borderColor: 'lightGray',
          })}
    >
      <SkeletonCircle mr={6} startColor="darkGrey" isLoaded={!isLoading}>
        <Flex
          justifyContent="center"
          alignItems="center"
          boxSize={8}
          bgColor="lightGray"
          borderRadius={BORDER_RADIUS_PILL}
        >
          <Icon as={icon} color="primary" boxSize={4} />
        </Flex>
      </SkeletonCircle>
      <Flex flexDir="column" flexGrow="inherit">
        <Flex justifyContent="space-between" alignItems="center">
          <Skeleton startColor="gray" isLoaded={!isLoading}>
            <Text>{name}</Text>
          </Skeleton>
          {difference && (
            <Skeleton startColor="gray" isLoaded={!isLoading}>
              <Text variant="small" color={difference.includes('-') ? 'red' : 'green'}>
                {difference}
              </Text>
            </Skeleton>
          )}
        </Flex>
        <Skeleton isLoaded={!isLoading} startColor="gray">
          <Flex pt={2} alignItems="end">
            <Heading variant="m">{label}</Heading>&nbsp;
            {subLabel && <Text color="darkGray">{subLabel}</Text>}
          </Flex>
        </Skeleton>
      </Flex>
    </Flex>
  )
}

const OverviewWidget = () => {
  const { data: { overview } = {}, loading: isOverviewLoading } = useOverviewQuery()

  const { data: txStats, isLoading: isTsxStatsLoading } = useGetTransactionStats()

  const { data: batchData, isLoading: isBatchIndexLoading } = useGetLatestBatch()

  const overviewData = {
    etherPrice: {
      label: formatUSD(overview?.priceUsd ?? 0),
      subLabel: `@ ${overview?.priceBtc.toFixed(6)} BTC`,
      difference: `(${overview?.priceChangePercentage24h.toFixed(2)}%)`,
    },
    marketCap: { label: formatUSD(overview?.marketCapUsd ?? 0) },
    transactionIndex: {
      label: `${txStats?.totalTxCount ?? 0} M`,
      subLabel: `(${txStats?.txCountPerMinute ?? 0} TPS)`,
    },
    latestBatch: {
      label: `${batchData?.batchIndex ?? 0}`,
    },
  }

  const isLoading = isOverviewLoading || isTsxStatsLoading || isBatchIndexLoading

  return (
    <Card display="flex" flexFlow="row wrap">
      <OverviewWidgetCard
        isLoading={isLoading}
        name="Ether Price"
        label={overviewData.etherPrice.label}
        subLabel={overviewData.etherPrice.subLabel}
        difference={overviewData.etherPrice.difference}
        icon={FaEthereum}
      />
      <OverviewWidgetCard
        isLoading={isLoading}
        name="Market Cap"
        label={overviewData.marketCap.label}
        icon={FaGlobe}
      />
      <OverviewWidgetCard
        name="Latest transaction index"
        label={overviewData.transactionIndex.label}
        subLabel={overviewData.transactionIndex.subLabel}
        icon={FaListAlt}
        isLoading={isLoading}
      />
      <OverviewWidgetCard
        name="Latest L1 transaction batch"
        label={overviewData.latestBatch.label}
        icon={FaCheck}
        isLoading={isLoading}
      />
    </Card>
  )
}

export default OverviewWidget
