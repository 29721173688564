import { Box, Flex, Link, Text } from '@chakra-ui/react'
import { useGetAggregatedTransactions } from 'generated/reactQueryClient'
import { useRouter } from 'next/router'
import { PAGE_LENGTH } from 'constants/common'
import { Routes } from 'constants/routes'
import AddressCard from 'components/AddressCard'
import ExternalLink from 'components/ExternalLink/ExternalLink'
import TransactionIcon from './TransactionIcon'
import WidgetSkeleton from './WidgetSkeleton'

const LatestTransactionsEnqueued = () => {
  const { push } = useRouter()
  const { data, isLoading } = useGetAggregatedTransactions({
    limit: PAGE_LENGTH,
    offset: 0,
    txType: 'L1L2',
  })

  return (
    <AddressCard
      flex={1}
      title="Latest L1 -> L2 Transactions"
      onViewAllClick={() => {
        void push(Routes.transactionsEnqueued())
      }}
    >
      {isLoading ? (
        <WidgetSkeleton />
      ) : (
        data?.data.map(({ l1BlockIndex, l1TxHash, l2TxHash }) => (
          <AddressCard.Item key={`${l1TxHash}${l2TxHash}`}>
            <Flex alignItems="center">
              <TransactionIcon />
              <Box overflow="hidden" ml={6}>
                <Flex alignItems="center">
                  <Text>Bk</Text>
                  <ExternalLink
                    ml={1}
                    href={`https://etherscan.io/block/${l1BlockIndex}`}
                    label={String(l1BlockIndex)}
                  />
                </Flex>
                <Flex alignItems="center">
                  <Text minW="fit-content">L1 Tx#</Text>
                  <ExternalLink
                    ml={1}
                    isTruncated
                    href={`https://etherscan.io/tx/${l1TxHash}`}
                    label={l1TxHash}
                  />
                  <Text ml={4} minW="fit-content">
                    L2 Tx#
                  </Text>
                  <Link
                    ml={1}
                    noOfLines={1}
                    href={Routes.transactionDetail({ txHash: String(l2TxHash), activeTab: 0 })}
                  >
                    <Text>{l2TxHash}</Text>
                  </Link>
                </Flex>
              </Box>
            </Flex>
          </AddressCard.Item>
        ))
      )}
    </AddressCard>
  )
}

export default LatestTransactionsEnqueued
